import gql from 'graphql-tag'
import PlayerBaseFragment from './PlayerBaseFragment'
import TurnBaseFragment from './TurnBaseFragment'

export default gql`
  fragment GameBaseFragment on Game {
    id
    bankGold
    bankSilver
    playersCount
    type
    isFull

    winner {
      ...PlayerBaseFragment
    }

    players {
      ...PlayerBaseFragment
    }

    turns {
      ...TurnBaseFragment
    }

    currentTurn {
      ...TurnBaseFragment
    }
  }

  ${PlayerBaseFragment}
  ${TurnBaseFragment}
`
