import gql from 'graphql-tag'

export default gql`
  fragment SpaceBaseFragment on Space {
    id
    index
    type
    size
  }
`
