import React from 'react'
import Response from './Response/index'

export default ({
  game: {
    currentTurn: {
      player: {
        silver,
      },
    },
  },
  game,
  ...rest,
}) => (
  <Response type='payElBackoSilver' disabled={silver < 4} game={game} {...rest}>
    Pay El Backo 4 silver pesos
  </Response>
)
