import Wagon from './Wagon'
import ElBacko from './ElBacko'
import Normal from './Normal'
import Alamo from './Alamo'
import PlayerWins from './PlayerWins'
import PlayersStart from './PlayersStart'

export default {
  wagon: Wagon,
  elBacko: ElBacko,
  normal: Normal,
  alamo: Alamo,
  playerWins: PlayerWins,
  playersStart: PlayersStart,
}
