import React, { Component } from 'react'
import _ from 'lodash'
import { forEachSeries } from 'p-iteration'
import sleep from 'es7-sleep'
import classNames from 'classnames'
import position from '../../lib/position'
import Avatar from './Avatar'
import playSound from './lib/playSound'
import styles from './index.module.css'

export default class Player extends Component {
  constructor(props) {
    super(props)

    this.state = {
      space: props.space,
      offset: _.random(0, 40),
      transform: '',
      lastTurn: {},
    }
  }

  async componentWillReceiveProps({ space, spaces, lastTurn, ...r }) {
    if (space.id === this.props.space.id) return
    if (!lastTurn) return
    if (lastTurn.id === this.state.lastTurn.id) return

    if (lastTurn.pathSpaces.length <= 12) {
      await forEachSeries(lastTurn.pathSpaces, async (pathSpace, i) => {
        this.setState({
          transform: 'translate3d(0, -10px, 0)',
        })

        await sleep(300)

        playSound('step')
        this.setState({
          space: pathSpace,
          transform: 'translate3d(0, 0, 0)',
        })

        await sleep(300)
      })
    }

    playSound(space.type)

    this.setState({
      lastTurn,
      space,
    })
  }

  isCurrentTurn() {
    return this.props.id === this.props.game.currentTurn.player.id
  }

  render() {
    return (
      <div
        className={classNames(
          styles.container,
          this.state.className && styles[this.state.className],
        )}
        style={{
          top: position(this.state.space, this.props.scalingFactor).top + this.state.offset * this.props.scalingFactor,
          left: position(this.state.space, this.props.scalingFactor).left + this.state.offset * this.props.scalingFactor,
          transform: this.state.transform,
        }}
      >
        <Avatar {...this.props} indicateCurrentTurn={this.isCurrentTurn()} />
      </div>
    )
  }
}
