import gql from 'graphql-tag'
import GameBaseFragment from './GameBaseFragment'

export default gql`
  query Game($id: ID!) {
    game(id: $id) {
      ...GameBaseFragment
    }
  }
  ${GameBaseFragment}
`
