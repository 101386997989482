import React from 'react'
import _ from 'lodash'
import styles from './Score.module.css'

export default ({
  player: {
    duelTurns,
  },
}) => (
  <div className={styles.container}>
    <div className={styles.innerContainer}>
      {_.map(_.reject(duelTurns, (dt) => _.isEmpty(dt.dices)), ({ id, dices }) => (
        <div key={id} className={styles.sum}>
          {_.sumBy(dices, 'value')}
        </div>
      ))}
    </div>
  </div>
)
