import gql from 'graphql-tag'
import SpaceBaseFragment from './SpaceBaseFragment'

export default gql`
  fragment PlayerBaseFragment on Player {
    id
    name
    color
    gold
    silver

    lastTurn {
      id
      pathSpaces {
        ...SpaceBaseFragment
      }
    }

    duelTurns {
      id

      dices {
        id
        value
      }
    }

    game {
      currentTurn {
        player {
          id
        }
        dices {
          id
          value
        }
      }
    }

    space {
      ...SpaceBaseFragment
    }
  }

  ${SpaceBaseFragment}
`
