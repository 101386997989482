import React, { Component } from 'react'
import _ from 'lodash'
import ReactDice from 'react-dice-complete'
import 'react-dice-complete/dist/react-dice-complete.css'
import styles from './index.module.css'

export default class Dices extends Component {
  constructor(props) {
    super(props)

    this.state = {
      rolling: false,
    }
  }

  start() {
    this.setState({
      rolling: true,
    })

    this.reactDice.rollAll()
  }

  end(dices) {
    this.setState({
      rolling: false,
    })

    this.reactDice.rollAll(_.map(dices, 'value'))
  }

  childrenProps() {
    return {
      start: this.start.bind(this),
      end: this.end.bind(this),
    }
  }

  render() {
    return (
      <>
        <div className={styles.container}>
          <ReactDice
            numDice={2}
            rollDone={() => {}}
            rollTime={this.state.rolling ? 20 : 0.5}
            ref={dice => this.reactDice = dice}
            faceColor='#162925'
            dotColor='#fff'
            disableIndividual
          />
        </div>

        {this.props.children(this.childrenProps())}
      </>
    )
  }
}
