import { Howl } from 'howler'

const SOUNDS = {
  step: '/sounds/step.mp3',
}

export default (type) => {
  if (!SOUNDS[type]) return

  const sound = new Howl({
    src: [SOUNDS[type]],
  })

  sound.play()
}
