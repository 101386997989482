import React from 'react'
import styles from './index.module.css'

export default ({
  currentTurn: {
    player: {
      name,
    },
  },
}) => (
  <div className={styles.container}>
    Waiting for {name} to play the turn...
  </div>
)
