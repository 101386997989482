import React from 'react'
import Logo from '../Logo/index'
import styles from './index.module.css'

export default ({ children }) => (
  <div className={styles.outer}>
    <Logo />

    <div className={styles.inner}>
      {children}
    </div>
  </div>
)
