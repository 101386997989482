import React from 'react'
import Response from './Response/index'

export default ({
  game: {
    bankGold,
    currentTurn: {
      player: {
        silver,
      },
    },
  },
  game,
  ...rest,
}) => (
  <Response type='tradeSilverToGold' disabled={silver < 5 || bankGold === 0} game={game} {...rest}>
    Trade 5 silvers to 1 gold
  </Response>
)
