import points from './points'

const left = ({ index }, scalingFactor) => (
  points[index].x * scalingFactor
)

const top = ({ index }, scalingFactor) => (
  points[index].y * scalingFactor
)

export default (space, scalingFactor) => ({
  left: left(space, scalingFactor),
  top: top(space, scalingFactor),
})
