import React from 'react'
import _ from 'lodash'
import {
  Mutation,
} from 'react-apollo'
import createGameMutation from './lib/createGameMutation'

export default ({ playersCount, type, children }) => (
  <Mutation
    mutation={createGameMutation}
    variables={{
      playersCount: _.toInteger(playersCount),
      type,
    }}
  >
    {(...props) => children(...props)}
  </Mutation>
)
