import React from 'react'
import Response from './Response/index'

export default ({
  game: {
    bankSilver,
    currentTurn: {
      player: {
        gold,
      },
    },
  },
  game,
  ...rest,
}) => (
  <Response type='tradeGoldToSilver' disabled={gold === 0 || bankSilver < 5} game={game} {...rest}>
    Trade 1 gold to 5 silvers
  </Response>
)
