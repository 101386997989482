import React from 'react'
import classNames from 'classnames'
import Avatar from '../Avatar/index'
import styles from './index.module.css'

export default ({
  center,
  player: {
    name,
    ...restPlayer,
  },
  ...rest,
}) => (
  <div className={classNames(styles.container, center && styles.center)}>
    <Avatar {...rest} {...restPlayer} />
    <div className={styles.name}>
      {name}
    </div>
  </div>
)
