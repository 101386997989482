import React from 'react'
import styles from './index.module.css'
import position from '../../lib/position'
import spaces from './spaces/index'

export default ({ type, scalingFactor, ...rest }) => {
  const Component = spaces[type]

  return (
    <div
      className={styles.container}
      style={{
        ...position(rest, scalingFactor),
        minWidth: `${140 * scalingFactor}px`,
        height: `${140 * scalingFactor}px`,
      }}
    >
      <Component scalingFactor={scalingFactor} />
    </div>
  )
}
