import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import apolloLink from './apolloLink'

const cache = new InMemoryCache()

export default new ApolloClient({
  link: apolloLink,
  cache,
})
