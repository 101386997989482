import React from 'react'
import _ from 'lodash'
import CenteredContainer from '../../CenteredContainer/index'
import Heading from '../../Heading/index'
import PlayerNameAndAvatar from '../../PlayerNameAndAvatar/index'

export default ({
  data: {
    game: {
      playersCount,
      players,
    },
    game,
  },
}) => (
  <CenteredContainer>
    <Heading>
      {players.length}/{playersCount} joined. Waiting for the rest...
    </Heading>

    <Heading>
      Current players:
    </Heading>
    <div>
      {_.map(players, (player) => (
        <PlayerNameAndAvatar key={player.id} player={player} />
      ))}
    </div>
  </CenteredContainer>
)
