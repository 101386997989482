import React from 'react'
import moment from 'moment'
import Countdown from 'react-countdown-now'

export default ({
  response: {
    timeoutAt,
    isDefault,
  },
  onComplete,
}) => (
  <Countdown
    date={moment(timeoutAt).toDate()}
    onComplete={() => isDefault && onComplete()}
    renderer={({ completed, seconds }) => (
      !completed && isDefault && <span>({seconds})</span>
    )}
  />
)
