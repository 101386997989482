import _ from 'lodash'
import playSound from './playSound'

export default (respond, { type, dices: { start } }) => () => {
  if (_.includes(['move', 'duel'], type)) {
    start()
  }

  playSound(type)

  return respond()
}
