import React from 'react'
import PlayerNameAndAvatar from '../../../PlayerNameAndAvatar/index'
import Title from '../Title/index'
import Responses from './Responses/index'
import Waiting from './Waiting/index'
import Dices from './Dices/index'
import styles from './index.module.css'
import isWaiting from './lib/isWaiting'

export default ({
  data: {
    game: {
      currentTurn: {
        player,
      },
      currentTurn,
    },
    game,
  },
  isShowingLabels = true,
  ...rest,
}) => (
  <div className={styles.container}>
    {isShowingLabels && <>
      <Title>
        Current turn
      </Title>
      <PlayerNameAndAvatar player={player} />
    </>}

    <Dices game={game}>
      {(dices) => (
        isWaiting(game) ? (
          <Waiting currentTurn={currentTurn} />
        ) : (
          <Responses game={game} dices={dices} {...rest} />
        )
      )}
    </Dices>
  </div>
)
