import { Component } from 'react'

export default class CallCreate extends Component {
  componentDidMount() {
    this.props.create({
      variables: {
        players: [
          {
            id: window.localStorage.getItem('currentPlayerId'),
          },
        ],
      },
    })
  }

  render() {
    return this.props.children
  }
}
