import React from 'react'
import classNames from 'classnames'
import styles from './index.module.css'

export default ({
  component: Component = 'button',
  className,
  ...rest,
}) => (
  <div>
    <Component className={classNames(styles.container, className)} {...rest} />
  </div>
)
