import React from 'react'
import {
  Mutation,
} from 'react-apollo'

import Button from '../../../../../Button/index'
import respondMutation from './lib/respondMutation'
import onCompleted from './lib/onCompleted'
import onClick from './lib/onClick'
import isLoading from './lib/isLoading'
import styles from './index.module.css'
import Timeout from './Timeout'

export default (props) => (
  <Mutation
    mutation={respondMutation}
    variables={{
      turnId: props.game.currentTurn.id,
      type: props.type,
    }}
    onCompleted={onCompleted(props)}
  >
    {(respond, result) => (
      <Button
        className={styles.container}
        onClick={onClick(respond, props)}
        disabled={isLoading(props, result)}
        {...props}
      >
        {isLoading(props, result) ? 'Loading...' : props.children} <Timeout onComplete={onClick(respond, props)} {...props} />
      </Button>
    )}
  </Mutation>
)
