import React from 'react'
import {
  Redirect,
} from 'react-router-dom'
import {
  Mutation,
} from 'react-apollo'
import CenteredContainer from '../../../CenteredContainer'
import Heading from '../../../Heading'
import joinGameMutation from './lib/joinGameMutation'
import CallJoin from './CallJoin'

export default ({
  match: {
    params: {
      gameId,
    },
  }
}) => (
  <Mutation
    mutation={joinGameMutation}
    variables={{
      gameId,
      playerId: window.localStorage.getItem('currentPlayerId'),
    }}
  >
    {(join, { loading, error, data }) => (
      <CallJoin join={join}>
        <CenteredContainer>
          <Heading>
            Joining game...
          </Heading>
        </CenteredContainer>
        {data && <Redirect to={`/games/${gameId}`} />}
      </CallJoin>
    )}
  </Mutation>
)
