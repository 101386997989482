import React from 'react'

import Logo from '../../Logo/index'
import Bank from './Bank'
import PlayersList from './PlayersList/index'
import CurrentTurn from './CurrentTurn/index'
import Log from './Log/index'

import styles from './index.module.css'

export default (props) => (
  <div className={styles.container}>
    <Logo medium />
    <CurrentTurn {...props} />
    <PlayersList {...props} />
    <Bank {...props} />
    <Log {...props} />
  </div>
)
