import React from 'react'
import {
  Query,
} from 'react-apollo'
import MultiplayerLobby from '../Multiplayer/Lobby/index'
import GameScreen from './GameScreen'

import query from './lib/query'
import subscribeToUpdates from './lib/subscribeToUpdates'

export default ({
  match: {
    params: {
      gameId,
    },
  },
}) => (
  <Query query={query} variables={{ id: gameId }}>
    {({ subscribeToMore, loading, error, data }) => {
      if (loading) return null
      if (error) return `Error: ${JSON.stringify(error)}`
      subscribeToUpdates(subscribeToMore, gameId)

      if (!data.game.isFull) return <MultiplayerLobby data={data} />

      return <GameScreen data={data} />
    }}
  </Query>
)
