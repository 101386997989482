import React from 'react'
import _ from 'lodash'
import { Formik, Field } from 'formik'
import {
  Redirect,
} from 'react-router-dom'
import Button from '../../../Button/index'
import styles from './index.module.css'
import CenteredContainer from '../../../CenteredContainer/index'
import Heading from '../../../Heading/index'
import Input from '../../../Input/index'
import PlayerNamesMissingError from './PlayerNamesMissingError'
import createGameSchema from './lib/createGameSchema'
import initialValues from './lib/initialValues'
import CreateGame from '../../../CreateGame/index'

export default ({
  match: {
    params: {
      playersCount,
    },
  },
}) => (
  <CenteredContainer>
    <CreateGame playersCount={playersCount} type='singleplayer'>
      {(create, { loading, data }) => (
        <Formik
          initialValues={initialValues(playersCount)}
          validationSchema={createGameSchema}
          onSubmit={(variables) => (
            create({ variables })
          )}
        >
          {({ values: { players }, handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <Heading>
                Player names
              </Heading>

              {_.map(players, ({ number }, index) => (
                <div key={index}>
                  <Input
                    component={Field}
                    className={styles.input}
                    name={`players.${index}.name`}
                    placeholder={`Player ${index + 1} name`}
                  />
                </div>
              ))}

              {!_.isEmpty(errors) && <PlayerNamesMissingError />}

              <Button type='submit' disabled={loading}>
                {loading ? 'Loading game...' : 'Start game'}
              </Button>
              {data && <Redirect to={`/games/${data.createGame.id}`} />}
            </form>
          )}
        </Formik>
      )}
    </CreateGame>
  </CenteredContainer>
)
