import React from 'react'
import _ from 'lodash'
import Space from './Space/index'

export default ({ spaces, ...rest }) => (
  <>
    {_.map(spaces, (space) => (
      <Space
        key={space.id}
        {...rest}
        {...space}
      />
    ))}
  </>
)
