import gql from 'graphql-tag'
import GameBaseFragment from './GameBaseFragment'

export default gql`
  subscription onGameUpdated($id: ID!) {
    gameUpdated(id: $id) {
      ...GameBaseFragment
    }
  }
  ${GameBaseFragment}
`
