import React from 'react'
import _ from 'lodash'

export default ({ dices }) => {
  if (!dices.length) return null

  return (
    <span>
      ({_.map(dices, 'value').join(', ')})
    </span>
  )
}
