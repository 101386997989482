import gql from 'graphql-tag'
import SpaceBaseFragment from '../../../../../lib/SpaceBaseFragment'

export default gql`
  mutation Respond($turnId: ID!, $type: String!) {
    respond(turnId: $turnId, type: $type) {
      id

      pathSpaces {
        ...SpaceBaseFragment
      }
      traded
      dices {
        id
        value
      }
    }
  }

  ${SpaceBaseFragment}
`
