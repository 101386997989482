import React from 'react'
import BrownTile from './BrownTile'

export default (props) => (
  <BrownTile {...props}>
    Players
    <br />
    Start
  </BrownTile>
)
