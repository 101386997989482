import React from 'react'
import {
  Query,
} from 'react-apollo'
import { SizeSensor } from 'libreact/lib/SizeSensor'
import { WindowSizeSensor } from 'libreact/lib/WindowSizeSensor'
import styles from './index.module.css'
import Spaces from './Spaces/index'
import Players from './Players/index'
import getScalingFactor from './lib/getScalingFactor'
import query from './lib/query'

export default ({
  data: {
    game: {
      id: gameId,
    },
  },
  ...rest,
}) => (
  <Query query={query} variables={{ gameId }}>
    {({ loading, error, data }) => {
      if (loading) return null
      if (error) return `Error: ${JSON.stringify(error)}`

      return (
        <WindowSizeSensor>
          {({ height, width }) => (
            <SizeSensor>
              {(size) => (
                <div
                  className={styles.container}
                  style={{
                    height: width < 700 ? `${width}px` : '80vh',
                    width: width < 700 ? `${width}px` : '80vh'
                  }}
                >
                  <Spaces scalingFactor={getScalingFactor(size)} {...data} />
                  <Players scalingFactor={getScalingFactor(size)} {...data} {...rest} />
                </div>
              )}
            </SizeSensor>
          )}
        </WindowSizeSensor>
      )
    }}
  </Query>
)
