import React from 'react'
import { WindowSizeSensor } from 'libreact/lib/WindowSizeSensor'
import Confetti from 'react-confetti'
import styles from './index.module.css'

export default ({
  player: {
    name,
  },
}) => (
  <div className={styles.container}>
    <WindowSizeSensor>
      {({ height, width }) => (
        <Confetti width={width} height={height} />
      )}
    </WindowSizeSensor>
    <div className={styles.name}>
      {name}
    </div>
    <div>
      is the winner!
    </div>
  </div>
)
