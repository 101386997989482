import React from 'react'
import _ from 'lodash'
import {
  Query,
} from 'react-apollo'
import {
  Link,
} from 'react-router-dom'
import CenteredContainer from '../../CenteredContainer'
import Button from '../../Button'
import Heading from '../../Heading'
import Item from './Item'
import query from './lib/query'

export default () => (
  <CenteredContainer>
    <Heading>
      Available games
    </Heading>

    <Query query={query}>
      {({ loading, error, data }) => {
        if (loading) return null
        if (error) return `Error: ${JSON.stringify(error)}`

        if (_.isEmpty(data.availableMultiplayerGames)) {
          return <Heading>No available multiplayer games to join.</Heading>
        }

        return (
          <div>
            {_.map(_.reverse(data.availableMultiplayerGames), (game) => (
              <Item key={game.id} game={game} />
            ))}
          </div>
        )
      }}
    </Query>

    <Heading>
      New game
    </Heading>

    <Button component={Link} to='/multiplayer/new'>
      New multiplayer game
    </Button>
  </CenteredContainer>
)
