import React from 'react'
import _ from 'lodash'
import Player from './Player/index'

export default ({ players, ...rest }) => (
  <>
    {_.map(players, ({ id, ...restPlayer }) => (
      <Player key={id} id={id} {...restPlayer} {...rest} />
    ))}
  </>
)
