import React from 'react'
import _ from 'lodash'
import {
  Link,
} from 'react-router-dom'
import Button from '../../../Button'
import styles from './index.module.css'

export default ({
  game: {
    id,
    players,
    playersCount,
  },
}) => (
  <div className={styles.container}>
    <div className={styles.description}>
      {_.first(players).name}'s game
    </div>
    <div className={styles.playerCounts}>
      {players.length}/{playersCount} players
    </div>
    <Button component={Link} to={`/games/${id}/join`}>
      Join
    </Button>
  </div>
)
