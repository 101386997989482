import React from 'react'
import _ from 'lodash'
import styles from './index.module.css'
import Move from './Move'
import MoveWagon from './MoveWagon'
import MoveBackward from './MoveBackward'
import GoBackToStart from './GoBackToStart'
import TradeDone from './TradeDone'
import TradeGoldToSilver from './TradeGoldToSilver'
import TradeSilverToGold from './TradeSilverToGold'
import Duel from './Duel'
import DuelLostHeader from './DuelLostHeader'
import PayElBackoSilver from './PayElBackoSilver'
import PayElBackoGold from './PayElBackoGold'
import PayElBackoAll from './PayElBackoAll'

const components = {
  duel: Duel,
  moveBackward: MoveBackward,
  goBackToStart: GoBackToStart,
  move: Move,
  moveWagon: MoveWagon,
  payElBackoSilver: PayElBackoSilver,
  payElBackoGold: PayElBackoGold,
  payElBackoAll: PayElBackoAll,
  tradeDone: TradeDone,
  tradeGoldToSilver: TradeGoldToSilver,
  tradeSilverToGold: TradeSilverToGold,
}

export default (props) => {
  const {
    game: {
      currentTurn: {
        type,
        responses,
      },
    },
  } = props

  return (
    <div className={styles.container}>
      {type === 'duelLost' && <DuelLostHeader {...props} />}

      {_.map(responses, (response) => {
        const Component = components[response.type]
        if (!Component) throw new Error(`Missing type: ${response.type}`)

        return <Component key={response.type} response={response} {...props} />
      })}
    </div>
  )
}
