import React from 'react'
import Avatar from '../../../../Avatar'

export default ({ scalingFactor, ...rest }) => (
  <Avatar
    style={{
      width: `${80 * scalingFactor}px`,
      height: `${80 * scalingFactor}px`,
    }}
    {...rest}
  />
)
