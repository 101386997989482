import { Howl } from 'howler'

const SOUNDS = {
  move: '/sounds/dices.mp3',
  duel: '/sounds/shot.mp3',
  moveBackward: '/sounds/sad.mp3',
  goBackToStart: '/sounds/sad.mp3',
  moveWagon: '/sounds/wagon.mp3',
  payElBackoAll: '/sounds/cash.mp3',
  payElBackoGold: '/sounds/cash.mp3',
  payElBackoSilver: '/sounds/cash.mp3',
  tradeGoldToSilver: '/sounds/cash.mp3',
  tradeSilverToGold: '/sounds/cash.mp3',
}

export default (type) => {
  if (!SOUNDS[type]) return

  const sound = new Howl({
    src: [SOUNDS[type]],
  })

  sound.play()
}
