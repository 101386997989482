import _ from 'lodash'
import subscription from './subscription'

export default _.once((subscribeToMore, id) => (
  subscribeToMore({
    document: subscription,
    variables: { id },
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev

      return { game: subscriptionData.data.gameUpdated }
    }
  })
))
