import gql from 'graphql-tag'

export default gql`
  query AvailableMultiplayerGames {
    availableMultiplayerGames {
      id
      playersCount
      players {
        id
        name
      }
    }
  }
`
