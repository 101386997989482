import gql from 'graphql-tag'
import PlayerBaseFragment from '../../lib/PlayerBaseFragment'
import SpaceBaseFragment from '../../lib/SpaceBaseFragment'

export default gql`
  query Board($gameId: ID!) {
    players(gameId: $gameId) {
      ...PlayerBaseFragment
    }

    spaces(gameId: $gameId) {
      ...SpaceBaseFragment
    }
  }

  ${PlayerBaseFragment}
  ${SpaceBaseFragment}
`
