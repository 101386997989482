import React from 'react'
import CurrentTurn from '../Meta/CurrentTurn'
import styles from './CurrentTurn.module.css'

export default ({
  game,
}) => (
  <div className={styles.container}>
    <CurrentTurn data={{ game }} />
  </div>
)
