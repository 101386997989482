import React from 'react'
import classNames from 'classnames'
import styles from './index.module.css'

export default ({
  component: Component = 'h1',
  className,
  ...rest,
}) => (
  <Component className={classNames(className, styles.container)} {...rest} />
)
