import React from 'react'
import _ from 'lodash'

import Title from '../Title/index'
import Dices from './Dices'
import filter from './lib/filter'
import styles from './index.module.css'

export default ({
  data: {
    game: {
      turns,
    },
  },
}) => (
  <div>
    <Title>
      Activity
    </Title>

    <div className={styles.items}>
      {_.map(filter(turns), ({
        id,
        type,
        player: {
          name,
        },
        traded,
        dices,
      }) => (
        <div key={id} className={styles.item}>
          {name}: {_.upperCase(type)} <Dices dices={dices} /> {traded && `(${_.lowerCase(traded)})`}
        </div>
      ))}
    </div>
  </div>
)
