import React from 'react'
import CenteredContainer from '../../CenteredContainer/index'
import Heading from '../../Heading/index'
import PlayersCountSelect from '../../PlayersCountSelect/index'

export default () => (
  <CenteredContainer>
    <Heading>
      Select players count for a new singleplayer game
    </Heading>

    <PlayersCountSelect hrefBase='/singleplayer/new/' />
  </CenteredContainer>
)
