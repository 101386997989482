import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Button from '../Button/index'
import styles from './index.module.css'

export default ({ hrefBase }) => (
  <div className={styles.container}>
    {_.map(_.range(2, 7), (n) => (
      <Button className={styles.item} key={n} component={Link} to={`${hrefBase}${n}`}>
        {n}
      </Button>
    ))}
  </div>
)
