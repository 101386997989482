import React from 'react'
import Avatar from '../../../../Avatar/index'
import styles from './index.module.css'

export default ({
  game: {
    currentTurn: {
      player: {
        color,
        name,
      },
    },
  },
}) => (
  <div className={styles.info}>
    <Avatar color={color} /> {name} lost the duel!
  </div>
)
