import React from 'react'
import Response from './Response/index'

export default ({
  game: {
    currentTurn: {
      player: {
        gold,
        silver,
      },
    },
  },
  game,
  ...rest,
}) => (
  <Response
    type='payElBackoAll'
    disabled={silver < 4 && gold === 0}
    game={game}
    {...rest}
  >
    Pay El Backo all your peros
  </Response>
)
