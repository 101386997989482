import React from 'react'
import classNames from 'classnames'
import base from './base.module.css'
import styles from './BrownTile.module.css'

export default ({ scalingFactor, children, ...rest }) => (
  <div
    className={classNames(base.container, styles.container)}
    style={{
      width: `${300 * scalingFactor}px`,
      marginLeft: `-${300 * scalingFactor / 4}px`,
      fontSize: `${45 * scalingFactor}px`,
    }}
    {...rest}
  >
    <div className={styles.children}>
      {children}
    </div>
  </div>
)
