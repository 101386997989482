import React from 'react'
import classNames from 'classnames'
import base from './base.module.css'
import styles from './Wagon.module.css'
import { ReactComponent as WagonIcon } from '../../../../../../assets/wagon.svg'

export default ({ scalingFactor }) => (
  <div className={classNames(base.container, styles.container)}>
    <WagonIcon
      className={styles.icon}
      style={{
        width: `${80 * scalingFactor}px`,
        height: `${80 * scalingFactor}px`,
      }}
    />
  </div>
)
