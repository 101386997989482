import React from 'react'
import Response from './Response/index'

export default ({
  game: {
    currentTurn: {
      player: {
        gold,
      },
    },
  },
  game,
  ...rest,
}) => (
  <Response type='payElBackoGold' disabled={gold === 0} game={game} {...rest}>
    Pay El Backo 1 gold
  </Response>
)
