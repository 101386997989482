import { Component } from 'react'

export default class CallJoin extends Component {
  componentDidMount() {
    this.props.join()
  }

  render() {
    return this.props.children
  }
}
