import React from 'react'
import _ from 'lodash'
import PlayerNameAndAvatar from '../../../PlayerNameAndAvatar/index'
import Title from '../Title/index'
import Coin from '../Coin'
import styles from './index.module.css'

export default ({
  data: {
    game: {
      players,
    },
  },
}) => (
  <div>
    <Title>
      Players
    </Title>

    {_.map(players, ({ id, gold, silver, space: { index }, ...rest }) => (
      <div key={id} className={styles.item}>
        <PlayerNameAndAvatar player={rest} />
        <div className={styles.coins}>
          <Coin type='gold' value={gold} />
          <Coin type='silver' value={silver} />
        </div>
      </div>
    ))}
  </div>
)
