export default ({
  type,
  currentTurn: {
    player: {
      id,
    },
  },
}) => (
  type === 'multiplayer' && window.localStorage.getItem('currentPlayerId') !== id
)
