import React from 'react'
import classNames from 'classnames'
import Sky from 'react-sky'
import { WindowSizeSensor } from 'libreact/lib/WindowSizeSensor'
import { ReactComponent as LeftRevolverIcon } from '../../../assets/left-revolver.svg'
import { ReactComponent as RightRevolverIcon } from '../../../assets/right-revolver.svg'
import PlayerNameAndAvatar from '../../PlayerNameAndAvatar/index'
import CurrentTurn from './CurrentTurn'
import Score from './Score'
import styles from './index.module.css'

export default ({
  game: {
    currentTurn: {
      player,
      otherPlayer,
    },
  },
  game,
}) => {
  const isCurrentFirst = player.id < otherPlayer.id
  const firstPlayer = isCurrentFirst ? player : otherPlayer
  const secondPlayer = isCurrentFirst ? otherPlayer : player

  return (
    <WindowSizeSensor>
      {({ width }) => (
        <div className={styles.container}>
          <div className={styles.title}>
            DUEL!
          </div>
          <div className={styles.innerContainer}>
            <div className={styles.player}>
              <LeftRevolverIcon className={classNames(styles.revolver, styles.leftRevolver)} />
              <PlayerNameAndAvatar player={firstPlayer} center large />
              <Score player={firstPlayer} />
            </div>
            <div className={styles.player}>
              <RightRevolverIcon className={classNames(styles.revolver, styles.rightRevolver)} />
              <PlayerNameAndAvatar player={secondPlayer} center large />
              <Score player={secondPlayer} />
            </div>
          </div>
          <CurrentTurn game={game} />
          <div className={styles.roses}>
            <Sky
              images={{
                0: '/rose1-white.svg',
                1: '/rose1-red.svg',
                2: '/rose2-white.svg',
                3: '/rose2-red.svg',
              }}
              size='50px'
              how={40}
              time={5}
            />
          </div>
        </div>
      )}
    </WindowSizeSensor>
  )
}
