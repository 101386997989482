import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.css'
import Button from '../Button/index'
import CenteredContainer from '../CenteredContainer/index'

export default () => (
  <CenteredContainer>
    <div>
      <Button className={styles.button} component={Link} to='/singleplayer/new'>
        Singleplayer
      </Button>
      <Button className={styles.button} component={Link} to='/multiplayer/new-player'>
        Multiplayer
      </Button>
    </div>
  </CenteredContainer>
)
