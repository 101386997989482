import React from 'react'
import classNames from 'classnames'
import styles from './index.module.css'

export default ({
  color,
  indicateCurrentTurn,
  large,
  style = {},
}) => (
  <div
    className={classNames(
      styles.container,
      indicateCurrentTurn && styles.currentTurn,
      large && styles.large,
    )}
    style={{
      backgroundColor: color,
      ...style,
    }}
  />
)
