import React from 'react'
import {
  Redirect,
} from 'react-router-dom'
import CenteredContainer from '../../../CenteredContainer'
import Heading from '../../../Heading'
import CreateGame from '../../../CreateGame/index'
import CallCreate from './CallCreate'

export default ({
  match: {
    params: {
      playersCount,
    },
  },
}) => (
  <CreateGame playersCount={playersCount} type='multiplayer'>
    {(create, { loading, error, data }) => (
      <>
        <CallCreate create={create}>
          <CenteredContainer>
            <Heading>
              Creating a new game...
            </Heading>
          </CenteredContainer>
        </CallCreate>
        {data && <Redirect to={`/games/${data.createGame.id}/join`} />}
      </>
    )}
  </CreateGame>
)
