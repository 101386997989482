import React from 'react'
import _ from 'lodash'
import { Formik, Field } from 'formik'
import {
  Redirect,
} from 'react-router-dom'
import {
  Mutation,
} from 'react-apollo'
import Button from '../../Button/index'
import styles from './index.module.css'
import CenteredContainer from '../../CenteredContainer/index'
import Heading from '../../Heading/index'
import Input from '../../Input/index'
import PlayerNamesMissingError from './PlayerNamesMissingError'
import createPlayerSchema from './lib/createPlayerSchema'
import initialValues from './lib/initialValues'
import createPlayerMutation from './lib/createPlayerMutation'

export default () => (
  <CenteredContainer>
    <Mutation mutation={createPlayerMutation}>
      {(create, { loading, data }) => {
        if (data) window.localStorage.setItem('currentPlayerId', data.createPlayer.id)

        return (
          <Formik
            initialValues={initialValues()}
            validationSchema={createPlayerSchema}
            onSubmit={(variables) => {
              create({ variables })
            }}
          >
            {({ values: { name }, handleSubmit, errors }) => (
              <form onSubmit={handleSubmit}>
                <Heading>
                  Select your nickname
                </Heading>

                <Input
                  component={Field}
                  className={styles.input}
                  name='name'
                  placeholder='Your nickname'
                />

                {!_.isEmpty(errors) && <PlayerNamesMissingError />}

                <Button type='submit' disabled={loading}>
                  {loading ? 'Loading game...' : 'Continue'}
                </Button>
                {data && <Redirect to='/multiplayer/games' />}
              </form>
            )}
          </Formik>
        )
      }}
    </Mutation>
  </CenteredContainer>
)
