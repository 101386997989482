import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import Main from './Main/index'
import NewSingleplayer from './Singleplayer/New/index'
import NewSingleplayerPlayers from './Singleplayer/New/Players/index'
import MultiplayerGames from './Multiplayer/Games/index'
import NewMultiplayer from './Multiplayer/New/index'
import NewMultiplayerCreate from './Multiplayer/New/Create/index'
import MultiplayerNewPlayer from './Multiplayer/NewPlayer/index'
import Game from './Game/index'
import JoinGame from './Multiplayer/Games/Join/index'

export default () => (
  <Router>
    <Switch>
      <Route exact path='/' component={Main} />
      <Route exact path='/games/:gameId' component={Game} />
      <Route exact path='/games/:gameId/join' component={JoinGame} />

      <Route exact path='/singleplayer/new' component={NewSingleplayer} />
      <Route exact path='/singleplayer/new/:playersCount' component={NewSingleplayerPlayers} />

      <Route exact path='/multiplayer/new-player' component={MultiplayerNewPlayer} />
      <Route exact path='/multiplayer/new' component={NewMultiplayer} />
      <Route exact path='/multiplayer/new/:playersCount' component={NewMultiplayerCreate} />
      <Route exact path='/multiplayer/games' component={MultiplayerGames} />
    </Switch>
  </Router>
)
