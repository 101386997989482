import gql from 'graphql-tag'
import PlayerBaseFragment from './PlayerBaseFragment'
import SpaceBaseFragment from './SpaceBaseFragment'

export default gql`
  fragment TurnBaseFragment on Turn {
    id
    type

    responses {
      type
      isDefault
      timeoutAt
    }

    player {
      ...PlayerBaseFragment
    }

    otherPlayer {
      ...PlayerBaseFragment
    }

    traded
    dices {
      id
      value
    }

    pathSpaces {
      ...SpaceBaseFragment
    }
  }

  ${PlayerBaseFragment}
  ${SpaceBaseFragment}
`
