import React, { Component } from 'react'
import sleep from 'es7-sleep'
import Board from './Board/index'
import Meta from './Meta/index'
import Winner from './Winner/index'
import Duel from './Duel/index'

import styles from './index.module.css'

export default class GameScreen extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: props.data.game.currentTurn.type,
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.data.game.currentTurn.id !== prevProps.data.game.currentTurn.id) {
      await sleep(7000)
      this.setState({
        type: this.props.data.game.currentTurn.type,
      })
    }
  }

  render() {
    if (this.props.data.game.currentTurn.type === 'duelLost') {
      return <Duel game={this.props.data.game} />
    }

    if (
      this.props.data.game.currentTurn.type === this.state.type &&
      this.state.type === 'duel'
    ) {
      return <Duel game={this.props.data.game} />
    }

    return (
      <>
        <div className={styles.container}>
          <div className={styles.board}>
            <Board data={this.props.data} />
          </div>
          <Meta data={this.props.data} />
        </div>

        {this.props.data.game.winner && <Winner player={this.props.data.game.winner} />}
      </>
    )
  }
}
