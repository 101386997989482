import React from 'react'
import classNames from 'classnames'
import base from './base.module.css'
import styles from './ElBacko.module.css'

export default ({ scalingFactor }) => (
  <div
    className={classNames(base.container, styles.container)}
    style={{
      fontSize: `${30 * scalingFactor}px`,
    }}
  >
    <div className={styles.content}>
      <div>
        El
      </div>
      <div>
        Backo
      </div>
    </div>
  </div>
)
