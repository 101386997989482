import React from 'react'
import Title from './Title'
import Coin from './Coin'
import styles from './Bank.module.css'

export default ({
  data: {
    game: {
      bankGold,
      bankSilver,
    },
  },
}) => (
  <div>
    <Title>
      Bank
    </Title>
    <div className={styles.coins}>
      <Coin type='gold' value={bankGold} />
      <Coin type='silver' value={bankSilver} />
    </div>
  </div>
)
